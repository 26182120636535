import React from 'react'
import { Chart,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
Chart.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend)

const optionsData={
    resposive:true,
    plugins:{
        legend:{
            position:'top'
        },
        title:{
            display:true,
            text:"Monthly Generation"
        }
    }
}
const datas={
     labels:["jan","feb","mar","apr","may","jun","july","aug","sep","oct","nov","dec"],
    datasets:[{label:'Output',
    data:[11,15,12,18,89,67,60,78,20,14,89,100],
    backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
      ],}]
}
const Analytic = () => {
   
  return (
    <div>
        <Bar options={optionsData} data={datas}/>
    </div>
  )
}

export default Analytic