import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageNav from '../Compnents/PageNav'
import { TextInputForm } from '../Compnents/Forms'
import { ClickButton } from '../Compnents/ClickButton';
import { useLocation } from 'react-router-dom';


const ModelCreation = () => {
    const location = useLocation();
    const { type, rowData } = location.state || {};
    const initialState = type === 'edit' ? { ...rowData } : {
        model_type: '',
    };
    const [formData, setFormData] = useState(initialState);
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setFormData({
            ...formData,
            [fieldName]: value
        });
    };
    return (
        <div>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg='12' md='12' xs='12' className='py-3'>
                        <PageNav pagetitle={"ModelCreation"}></PageNav>
                    </Col>
                    <Col lg='4' md='4' xs='12' className='py-3'>
                        <TextInputForm placeholder={"Model Type"}
                            name='model_type'
                            value={formData.model_type}
                            onChange={(e) => handleChange(e, 'model_type')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='12'>
                        <div className='py-5 text-center'>
                            <ClickButton label={<> Submit</>}></ClickButton>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ModelCreation