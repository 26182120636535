import React, { useState, useEffect } from "react";
import { Container, Col, Row, Modal } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../Compnents/Table";
import { TextInputForm } from "../Compnents/Forms";
import Pagnation from "../Compnents/Pagnation";
import { Buttons, ClickButton } from "../Compnents/ClickButton";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdCloseCircle } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Navigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { PageTitle } from "../Compnents/PageTitle";
const UserTablehead = [
  "No",
  "Grid Fault Code",
  "Grid Fault Describtion",
  "Action",
];
// const UserData = [
//     { values: ["1", "TamilNadu", "Palladam", "PAL",] },
//     { values: ["2", "Andrapradesh", "Davanigera", "DVG",] },
//     { values: ["3", "Karnataka", "Bellari", "BRI",] },]

// const DropList = [

//     {
//         value: "TN",
//         title: "Tamil Nadu"
//     },
//     {
//         value: "PY",
//         title: "Pondycheri"
//     },
//     {
//         value: "KL",
//         title: "Kerala"
//     }, {
//         value: "TL",
//         title: "Telugana"
//     }
// ];
const UserData = [
  {
    id: "1",
    grid_fault_code: "TamilNadu",
    Sigrid_faultdescribtionte: "Palladam",
  },
];
const GridFault = ({ modeltitle = "Create", labelname = "Upload Excel" }) => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [addError, setaddError] = useState(false);

  const closeErrorModal = () => {
    setaddError(false);
    setCrtGridfauld(null);
  };
  const openErrorModal = () => setaddError(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLoad = () => {
    window.location.reload();
  };
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const navigate = useNavigate();
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
        grid_fault_code: "",
        grid_fault_describtion: "",
      };
  const [formData, setFormData] = useState(initialState);
  console.log("formData", formData);
  const [crtGridfauld, setCrtGridfauld] = useState(null);
  console.log("crtGridfauld", crtGridfauld);
  const handleEditClick = (rowdata) => {
    setCrtGridfauld(rowdata);
    setaddError(true);
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtGridfauld != null) {
      setCrtGridfauld({
        ...crtGridfauld,
        [fieldName]: value,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/grid_fault/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: searchText,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data.grid_fault);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [searchText]);
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async () => {
    try {
      if (
        formData.grid_fault_code === "" ||
        formData.grid_fault_describtion === ""
      ) {
        if (formData.grid_fault_code === "") {
          errorAlert("Name is Must");
        } else if (formData.grid_fault_describtion === "") {
          errorAlert("Mobile Number is Must");
        }
      } else {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/grid_fault/create.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        console.log(formData);
        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          setFormData({
            grid_fault_code: "",
            grid_fault_describtion: "",
          });
          fetchData();
          successAlert(responseData.msg);
          setTimeout(() => {
            closeErrorModal();
          }, 2000);
        } else if (responseData.status === 400) {
          toast.error("Missing required fields!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/grid_fault/update.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Include the company ID in the request
            grid_fault_id: crtGridfauld.grid_fault_id,
            grid_fault_code: crtGridfauld.grid_fault_code,
            grid_fault_describtion: crtGridfauld.grid_fault_describtion,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        successAlert(responseData.msg);
        setTimeout(() => {
          closeErrorModal();
        }, 2000);

        // Navigate to the user list page after a delay
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.msg);
    }

    setLoading(false);
  };
  const [sortOrder, setSortOrder] = useState("asc");
  const handleSort = () => {
    const sortedData = [...userData].sort((a, b) => {
      if (sortOrder === "asc") {
        return String(a.grid_fault_code).localeCompare(
          String(b.grid_fault_code)
        );
      } else {
        return String(b.grid_fault_code).localeCompare(
          String(a.grid_fault_code)
        );
      }
    });
    setUserData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="3" className="align-self-center">
            <div className="page-nav py-3">
              <span class="nav-list">Grid Fault</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="9" className="align-self-center py-3">
            <div className="d-flex justify-content-end">
              {/* <div className="px-2">
                <ClickButton
                  label={<>Upload Excel</>}
                  className="create-btn "
                  onClick={handleShowModal}
                ></ClickButton>
              </div> */}
              <div className="px-2">
                <ClickButton
                  label={<>Add New</>}
                  className="create-btn "
                  onClick={openErrorModal}
                ></ClickButton>
              </div>
            </div>
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Grid Fault "}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search Grid Fault"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={12} xs={12} className="py-2 text-end">
            {/* <Button onClick={handleShow} className='filter' >
                            <span className='me-2'><IoFilter /></span>Filter
                        </Button> */}
            <Col lg="12" md="12" xs="6" className="py-1">
              {/* <Button onClick={handleLoad} className='filter' >
                                <span className='me-2'><IoFilter /></span>Undo Filter
                            </Button> */}
            </Col>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
            >
              <Offcanvas.Body>
                <Row>
                  <Col lg="6">
                    <PageTitle PageTitle={<>GridFault</>} />
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div className="text-end">
                      <Buttons
                        onClick={handleClose}
                        lable={
                          <>
                            <MdOutlineClose />
                          </>
                        }
                      ></Buttons>
                    </div>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <TextInputForm
                        placeholder={"Grid Fault code"}
                        labelname={"Grid Fault code"}
                        name="grid_fault_code"
                        value={formData.grid_fault_code}
                        onChange={(e) => handleChange(e, "grid_fault_code")}
                      ></TextInputForm>
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <TextInputForm
                        placeholder={"Grid Fault Description"}
                        labelname={"Grid Fault Description"}
                        name="grid_fault_describtion"
                        value={formData.grid_fault_describtion}
                        onChange={(e) =>
                          handleChange(e, "grid_fault_describtion")
                        }
                      ></TextInputForm>
                    </Col>
                    <Col lg="12" className="align-self-center">
                      <div className="my-5 text-center">
                        <ClickButton label={<>Clear</>}></ClickButton>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          {/* <Col lg={3} md={12} xs={12} className='py-2'>
                        <Pagnation></Pagnation>
                    </Col> */}
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              <div className="bg-light">
                <div className="user-data">
                  <div className="sno">01</div>
                  <div className="user-name">
                    <div>Boopathi</div>
                    <span className="role"> Super Admin</span>
                  </div>
                  <div className="action-button">
                    <FaAngleRight />
                  </div>
                </div>
              </div>
              <div className="user-data">
                <div className="sno">02</div>
                <div className="user-name">
                  <div>Selvaraj</div>
                  <span className="role"> Super Admin</span>
                </div>
                <div className="action-button">
                  <FaAngleRight />
                </div>
              </div>
              <div className="user-data">
                <div className="sno">03</div>
                <div className="user-name">
                  <div>Boopathi</div>
                  <span className="role"> Super Admin</span>
                </div>
                <div className="action-button">
                  <FaAngleRight />
                </div>
              </div>
              <TableUI
                headers={UserTablehead}
                body={userData}
                pageview={"yes"}
                type="Gridfault"
                handleEditClick={handleEditClick}
                onDelete={fetchData}
                style={{ borderRadius: "5px" }}
                onSort={handleSort}
              />
            </div>
          </Col>
          {/* <Col lg={12} md={12} xs={12}>
                        <Pagnation></Pagnation>
                    </Col> */}
        </Row>
      </Container>
      <>
        <Modal show={addError} onHide={closeErrorModal} size="lg" centered>
          <Modal.Header>
            <Modal.Title> Grid Fault Creation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col lg="12" className="py-3">
                  <TextInputForm
                    placeholder={"Grid Fault code"}
                    labelname={"Grid Fault code"}
                    name="grid_fault_code"
                    value={
                      crtGridfauld != null
                        ? crtGridfauld.grid_fault_code
                        : formData.grid_fault_code
                    }
                    onChange={(e) => handleChange(e, "grid_fault_code")}
                  ></TextInputForm>
                </Col>
                <Col lg="12" className="py-3">
                  <TextInputForm
                    placeholder={"Grid Fault Description"}
                    labelname={"Grid Fault Description"}
                    name="grid_fault_describtion"
                    value={
                      crtGridfauld != null
                        ? crtGridfauld.grid_fault_describtion
                        : formData.grid_fault_describtion
                    }
                    onChange={(e) => handleChange(e, "grid_fault_describtion")}
                  ></TextInputForm>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Col lg="12" className="py-5 align-self-center">
              <div className="text-center">
                {crtGridfauld != null ? (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <div className="d-flex justify-content-center">
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={closeErrorModal}
                        ></ClickButton>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <div className="d-flex justify-content-center">
                      <span className="mx-2">
                        <ClickButton
                          label={<> Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={closeErrorModal}
                        ></ClickButton>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header>
            <Modal.Title>
              {modeltitle} {labelname ? labelname : "Model"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputForm labelname={labelname} />
          </Modal.Body>
          <Modal.Footer>
            <ClickButton label={<>Cancel</>} onClick={handleCloseModal} />
            <ClickButton label={<>Submit</>} onClick={handleCloseModal} />
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default GridFault;
