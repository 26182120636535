import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../Compnents/Table";
import Pagnation from "../Compnents/Pagnation";
import { Buttons, ClickButton } from "../Compnents/ClickButton";
import { TextInputForm } from "../Compnents/Forms";
import { useNavigate } from "react-router-dom";
import MobileView from "../Compnents/MobileView";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdCloseCircle } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { PageTitle } from "../Compnents/PageTitle";
import { useLocation } from "react-router-dom";
const UserTablehead = ["No", "Customer Name", "WTG NO", " LOC.No", "Action"];
// const UserData = [
//     { values: ["1", "Sankar", "Palladam", "PAL",] },
//     { values: ["2", "Ranjith", "Davanigera", "DVG",] },
//     { values: ["3", "Kannan", "Bellari", "BRI",] },]
const UserData = [
  {
    id: "1",
    CustomerName: "ranjith",
    Turbine: "Bellari",
    Site: "BRI",
  },
];
const Turbine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          turbine_id: "",
          date: "",
          customer_id: "",
          customername_id: "",
          wtg_no: "",
          loc_no: "",
        };
  const [formData, setFormData] = useState(initialState);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLoad = () => {
    window.location.reload();
  };
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  console.log("userData", userData);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/turbine/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: searchText,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data.turbine);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [searchText]);

  const handleSort = (key, direction) => {
    const sortedData = [...userData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUserData(sortedData);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list">Turbine</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/master/turbine/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Turbine"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search Turbine "}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={12} xs={12} className="py-2 text-end">
            {/* <Button onClick={handleShow} className='filter' >
                            <span className='me-2'><IoFilter /></span>Filter
                        </Button> */}
            <Col lg="12" md="12" xs="6" className="py-1">
              {/* <Button onClick={handleLoad} className='filter' >
                                <span className='me-2'><IoFilter /></span>Undo Filter
                            </Button> */}
            </Col>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
            >
              <Offcanvas.Body>
                <Row>
                  <Col lg="6">
                    <PageTitle PageTitle={<>Turbine</>} />
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div className="text-end">
                      <Buttons
                        onClick={handleClose}
                        lable={
                          <>
                            <MdOutlineClose />
                          </>
                        }
                      ></Buttons>
                    </div>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <TextInputForm
                        labelname={"Customer Name"}
                        name="Customer Name"
                        placeholder={"Customer Name"}
                        value={formData.customername_id}
                        onChange={(e) => handleChange(e, "customername_id")}
                      ></TextInputForm>
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <TextInputForm
                        labelname={"WTG No"}
                        placeholder={"WTG No"}
                        name="WTG No"
                        value={formData.wtg_no}
                        onChange={(e) => handleChange(e, "wtg_no")}
                      ></TextInputForm>
                    </Col>
                    <Col lg="12" className="align-self-center">
                      <div className="my-5 text-center">
                        <ClickButton label={<>Clear</>}></ClickButton>
                      </div>
                    </Col>
                  </Row>
                  {/* <span className='mx-2'>
                    <ClickButton label={<>Filter</>} onClick={handleFillter} />
                  </span> */}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          {/* <Col lg={3} md={12} xs={12} className='py-2'>
                        <Pagnation></Pagnation>
                    </Col> */}
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              <TableUI
                headers={UserTablehead}
                body={userData}
                type="turbine"
                pageview={"yes"}
                onDelete={fetchData}
                style={{ borderRadius: "5px" }}
                onSort={handleSort}
              />
              <MobileView
                sno={"01"}
                FactoryName={"Anand Fireworks"}
                Place={"Vettrilaiyurani"}
              ></MobileView>
            </div>
          </Col>
          {/* <Col lg={12} md={12} xs={12}>
                        <Pagnation></Pagnation>
                    </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default Turbine;
