import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { DropDownUI, TextInputForm, Calender } from "../Compnents/Forms";
import { ClickButton } from "../Compnents/ClickButton";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import moment from "moment/moment";
import * as XLSX from "xlsx";
import { Spinner } from 'react-bootstrap';
const types = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];
const DgReport = () => {
  const [formDatarep, setFormDatarep] = useState({
    customer_name: [],
    wtg_no: [],
    report: "",
    from_date: "",
    to_date: "",
  });
  console.log("formDatarep", formDatarep);
  const setDateOfBirths = (date) => {
    setFormDatarep((prevData) => ({
      ...prevData,
      date: date,
    }));
  };

  const setLabel = (value, field) => {
    setFormDatarep({
      ...formDatarep,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormDatarep({
      ...formDatarep,
      [fieldName]: value,
    });
  };
  const [userTurbine, setUserTurbine] = useState([]);
  const fetchDataTurbine = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/turbine/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUserTurbine(responseData.data.turbine);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchDataTurbine(); // Call fetchData directly in useEffect
  }, []);

  const [userCustomer, setUserCustomer] = useState([]);
  const fetchDataCustomer = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/customer/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUserCustomer(responseData.data.customer);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchDataCustomer(); // Call fetchData directly in useEffect
  }, []);

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/daily_generation/dgrreport.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_unique_id: formDatarep.customer_name,
            wtg_no: formDatarep.wtg_no,
            report: formDatarep.report,
            from_date: formDatarep.from_date,
            to_date: formDatarep.to_date,
          }),
        }
      );
      console.log(
        "api",
        JSON.stringify({
          customer_unique_id: formDatarep.customer_name,
          wtg_no: formDatarep.wtg_no,
          report: formDatarep.report,
          from_date: formDatarep.from_date,
          to_date: formDatarep.to_date,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.status === 200) {
        setUserData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      console.error("Error fetching report data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };
  const handleSelectChange = (selectedOptions) => {
    setFormDatarep({
      ...formDatarep,
      customer_name: selectedOptions.map((option) => option.value),
    });
  };

  const handleTurbineSelectChange = (selectedOptions) => {
    setFormDatarep((prevData) => ({
      ...prevData,
      wtg_no: selectedOptions.map((option) => option.value),
    }));
  };

  const customerOptions = userCustomer.map((customer) => ({
    value: customer.customer_unique_id,
    label: customer.customer_name,
  }));

  const filteredTurbineOptions = userTurbine
    .filter((turbine) =>
      formDatarep.customer_name.includes(turbine.customername_id)
    )
    .map((turbine) => ({
      value: turbine.wtg_no,
      label: turbine.wtg_no,
    }));
  const downloadExcel = () => {
    const formattedData = userData.map((data, index) => {
      if (formDatarep.report === "daily") {
        return {
          "S No": index + 1,
          Date: moment(data.dg_date).format('DD-MM-YYYY'),
          "Customer Name": data.customer_name || '',
          "Turbine No": data.wtg_no || '',
          "Location No": data.loc_no || '',
          "HTSC No": data.htsc_no || '',
          Site: data.site_name || '',
          "Gen 0": data.gen_zero || 0,
          "Gen 1": data.gen_one || 0,
          "Gen 2": data.gen_two || 0,
          "Total Production": data.total_production || 0,
          "KWH Import EB": data.kwh_imp || 0,
          "KWH Export EB": data.kwh_exp || 0,
          "Kvarh Imp EB": data.kvarh_imp || 0,
          "Kvarh Exp EB": data.kvarh_exp || 0,
          "Net Exp": data.net_exp || 0,
          "Gen 1 hrs": data.gen_onehrs || 0,
          "Gen 2 hrs": data.gen_twohrs || 0,
          "Gen Hrs": data.gen_hourtotal || 0,
          "M/c Fault hrs": data.error_overtotal || "00:00",
          "M/c Maintenance hrs": data.maintenance_overtotal || "00:00",
          "Grid fault hrs": data.gridfault_overtotal || "00:00",
          "Grid down hrs": data.griddrop_overtotal || "00:00",
          "Lull hrs": data.lull_hrs || 0,
          "Machine Availability %": data.machine_availability || 0,
          "Grid Availability %": data.grid_availability || 0,
          "Kvarh Imp %xp": data.kvarh_imp_per || 0,
          "Generation @100 % Grid": data.generation_grid || 0,
          "Diff. (Panel Vs EB)": data.diff_panel_vs_eb || 0,
          "Diff. (Panel Vs EB) %": data.diff_panel_eb_per || 0,
          "Power factor": data.power_factor || 0,
          "Lost KWH - Breakdown (units)": data.breakdown || 0,
          "Lost KWH - Maintenance (units)": data.Maintenance || 0,
          "Lost KWH - Grid Fault (units)": data.grid_fault || 0,
          "Lost KWH - Grid Down (units)": data.grid_down || 0,
          "Lost KWH - Breakdown (%)": data.breakdown_per || 0,
          "Lost KWH - Maintenance (%)": data.maintenance_per || 0,
          "Lost KWH - Grid Fault (%)": data.grid_fault_per || 0,
          "Lost KWH - Grid Down (%)": data.grid_down_per || 0,
          "Availability Impact (%)": data.availablity_impact || 0,
          Rating: data.rating || 0,
          "Kvarh Exp %": data.kvarh_exp_per || 0,
          "Turbine OK Hrs": data.turbine_ok_hrs || 0,
          "Line hrs": data.line_hrs || 0,
          "Line OK hrs": data.line_ok_hrs || 0,
          "Line loss (Units)": data.diff_panel_vs_eb || 0,
        };
      } else if (formDatarep.report === "monthly") {
        return {
          "S No": index + 1,
          Date: data.month,
          "Customer Name": data.customer_name || '',
          "Turbine No": data.wtg_no || '',
          "Location No": data.loc_no || '',
          "HTSC No": data.htsc_no || '',
          Site: data.site_name || '',
          "Gen 0": data.gen_zero || 0,
          "Gen 1": data.gen_one || 0,
          "Gen 2": data.gen_two || 0,
          "Total Production": data.total_production || 0,
          "KWH Import EB": data.kwh_imp || 0,
          "KWH Export EB": data.kwh_exp || 0,
          "Kvarh Imp EB": data.kvarh_imp || 0,
          "Kvarh Exp EB": data.kvarh_exp || 0,
          "Net Exp": data.net_exp || 0,
          "Gen 1 hrs": data.gen_onehrs || 0,
          "Gen 2 hrs": data.gen_twohrs || 0,
          "Gen Hrs": data.gen_hourtotal || 0,
          "M/c Fault hrs": data.error_overtotal || "00:00",
          "M/c Maintenance hrs": data.maintenance_overtotal || "00:00",
          "Grid fault hrs": data.gridfault_overtotal || "00:00",
          "Grid down hrs": data.griddrop_overtotal || "00:00",
          "Lull hrs": data.lull_hrs || 0,
          "Machine Availability %": data.machine_availability || 0,
          "Grid Availability %": data.grid_availability || 0,
          "Kvarh Imp %xp": data.kvarh_imp_per || 0,
          "Generation @100 % Grid": data.generation_grid || 0,
          "Diff. (Panel Vs EB)": data.diff_panel_vs_eb || 0,
          "Diff. (Panel Vs EB) %": data.diff_panel_eb_per || 0,
          "Power factor": data.power_factor || 0,
          "Lost KWH - Breakdown (units)": data.breakdown || 0,
          "Lost KWH - Maintenance (units)": data.Maintenance || 0,
          "Lost KWH - Grid Fault (units)": data.grid_fault || 0,
          "Lost KWH - Grid Down (units)": data.grid_down || 0,
          "Lost KWH - Breakdown (%)": data.breakdown_per || 0,
          "Lost KWH - Maintenance (%)": data.maintenance_per || 0,
          "Lost KWH - Grid Fault (%)": data.grid_fault_per || 0,
          "Lost KWH - Grid Down (%)": data.grid_down_per || 0,
          "Availability Impact (%)": data.availablity_impact || 0,
          Rating: data.rating || 0,
          "Kvarh Exp %": data.kvarh_exp_per || 0,
          "Turbine OK Hrs": data.turbine_ok_hrs || 0,
          "Line hrs": data.line_hrs || 0,
          "Line OK hrs": data.line_ok_hrs || 0,
          "Line loss (Units)": data.diff_panel_vs_eb || 0,
        };
      }
      else if (formDatarep.report === "yearly") {
        return {
          "S No": index + 1,
          Year: data.year,
          "Customer Name": data.customer_name || '',
          "Turbine No": data.wtg_no || '',
          "Location No": data.loc_no || '',
          "HTSC No": data.htsc_no || '',
          Site: data.site_name || '',
          "Gen 0": data.gen_zero || 0,
          "Gen 1": data.gen_one || 0,
          "Gen 2": data.gen_two || 0,
          "Total Production": data.total_production || 0,
          "KWH Import EB": data.kwh_imp || 0,
          "KWH Export EB": data.kwh_exp || 0,
          "Kvarh Imp EB": data.kvarh_imp || 0,
          "Kvarh Exp EB": data.kvarh_exp || 0,
          "Net Exp": data.net_exp || 0,
          "Gen 1 hrs": data.gen_onehrs || 0,
          "Gen 2 hrs": data.gen_twohrs || 0,
          "Gen Hrs": data.gen_hourtotal || 0,
          "M/c Fault hrs": data.error_overtotal || "00:00",
          "M/c Maintenance hrs": data.maintenance_overtotal || "00:00",
          "Grid fault hrs": data.gridfault_overtotal || "00:00",
          "Grid down hrs": data.griddrop_overtotal || "00:00",
          "Lull hrs": data.lull_hrs || 0,
          "Machine Availability %": data.machine_availability || 0,
          "Grid Availability %": data.grid_availability || 0,
          "Kvarh Imp %xp": data.kvarh_imp_per || 0,
          "Generation @100 % Grid": data.generation_grid || 0,
          "Diff. (Panel Vs EB)": data.diff_panel_vs_eb || 0,
          "Diff. (Panel Vs EB) %": data.diff_panel_eb_per || 0,
          "Power factor": data.power_factor || 0,
          "Lost KWH - Breakdown (units)": data.breakdown || 0,
          "Lost KWH - Maintenance (units)": data.Maintenance || 0,
          "Lost KWH - Grid Fault (units)": data.grid_fault || 0,
          "Lost KWH - Grid Down (units)": data.grid_down || 0,
          "Lost KWH - Breakdown (%)": data.breakdown_per || 0,
          "Lost KWH - Maintenance (%)": data.maintenance_per || 0,
          "Lost KWH - Grid Fault (%)": data.grid_fault_per || 0,
          "Lost KWH - Grid Down (%)": data.grid_down_per || 0,
          "Availability Impact (%)": data.availablity_impact || 0,
          Rating: data.rating || 0,
          "Kvarh Exp %": data.kvarh_exp_per || 0,
          "Turbine OK Hrs": data.turbine_ok_hrs || 0,
          "Line hrs": data.line_hrs || 0,
          "Line OK hrs": data.line_ok_hrs || 0,
          "Line loss (Units)": data.diff_panel_vs_eb || 0,
        };
      }
    });
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, "report.xlsx");
  };
  const renderTable = () => {
    if (formDatarep.report === "daily") {
      return (
        <div className="table-wrapperDgReport">
          <Table striped bordered hover  >
            <thead>
              <tr>

                <th >S.No</th>
                <th >Date</th>
                <th >Customer Name</th>
                <th >WEG No.</th>
                <th >Loc. No</th>
                <th >HTSC No. </th>
                <th >Site </th>
                <th >Gen 0</th>
                <th >Gen 1</th>
                <th >Gen 2</th>
                <th >Total Production</th>
                <th >KWH Import EB</th>
                <th >KWH Export EB</th>
                <th >Kvarh Imp EB</th>
                <th >Kvarh exp EB</th>
                <th >Net Exp</th>
                <th >Gen 1 hrs</th>
                <th >Gen 2 hrs</th>
                <th >Gen Hrs</th>
                <th >M/c Fault hrs</th>
                <th >M/c Maintenance hrs</th>
                <th >Grid fault hrs</th>
                <th >Grid down hrs</th>
                <th >Lull hrs</th>
                <th >Machine Availability %</th>
                <th >Grid Availability %</th>
                <th >Kvarh Imp %xp</th>
                <th >Generation @100 % Grid</th>
                <th >Diff. (Panel Vs EB)</th>
                <th >Diff. (Panel Vs EB) %</th>
                <th >Power factor</th>

                <th >Lost KWH - Breakdown (units)  </th>
                <th >Lost KWH - Maintenance (units)  </th>
                <th >Lost KWH - Grid Fault (units)  </th>
                <th >Lost KWH - Grid Down (units) </th>
                <th >Lost KWH - Breakdown (%)</th>
                <th >Lost KWH - Maintenance (%)</th>
                <th >Lost KWH - Grid Fault (%)</th>
                <th >Lost KWH - Grid Down (%)</th>
                <th >Availablity Impact (%)</th>

                <th >Rating</th>
                <th >Kvarh Exp % </th>
                <th >Turbine OK Hrs</th>
                <th >Line hrs</th>
                <th >Line OK hrs</th>
                <th >Line loss (Units)</th>

              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{moment(data.dg_date).format('DD-MM-YYYY')}</td>
                  <td>{data.customer_name}</td>
                  <td>{data.wtg_no || 0}</td>
                  <td>{data.loc_no || 0}</td>
                  <td>{data.htsc_no || 0}</td>
                  <td>{data.site_name}</td>
                  <td>{data.gen_zero || 0}</td>
                  <td>{data.gen_one || 0}</td>
                  <td>{data.gen_two || 0}</td>
                  <td>{data.total_production || 0}</td>
                  <td>{data.kwh_imp || 0}</td>
                  <td>{data.kwh_exp || 0}</td>
                  <td>{data.kvarh_imp || 0}</td>
                  <td>{data.kvarh_exp || 0}</td>
                  <td>{data.net_exp || 0}</td>
                  <td>{data.gen_onehrs || 0}</td>
                  <td>{data.gen_twohrs || 0}</td>
                  <td>{data.gen_hourtotal || 0}</td>
                  <td>{data.error_overtotal || "00:00"}</td>
                  <td>{data.maintenance_overtotal || "00:00"}</td>
                  <td>{data.gridfault_overtotal || "00:00"}</td>
                  <td>{data.griddrop_overtotal || "00:00"}</td>
                  <td>{data.lull_hrs || 0}</td>
                  <td>{data.machine_availability || 0}</td>
                  <td>{data.grid_availability || 0}</td>
                  <td>{data.kvarh_imp_per || 0}</td>
                  <td>{data.generation_grid || 0}</td>
                  <td>{data.diff_panel_vs_eb || 0}</td>
                  <td>{data.diff_panel_eb_per || 0}</td>
                  <td>{data.power_factor || 0}</td>
                  <td>{data.breakdown || 0}</td>
                  <td>{data.Maintenance || 0}</td>
                  <td>{data.grid_fault || 0}</td>
                  <td>{data.grid_down || 0}</td>
                  <td>{data.breakdown_per || 0}</td>
                  <td>{data.maintenance_per || 0}</td>
                  <td>{data.grid_fault_per || 0}</td>
                  <td>{data.grid_down_per || 0}</td>
                  <td>{data.availablity_impact || 0}</td>
                  <td>{data.rating || 0}</td>
                  <td>{data.kvarh_exp_per || 0}</td>
                  <td>{data.turbine_ok_hrs || 0}</td>
                  <td>{data.line_hrs || 0}</td>
                  <td>{data.line_ok_hrs || 0}</td>
                  <td>{data.diff_panel_vs_eb || 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    } else if (formDatarep.report === "monthly") {
      return (
        // <div style={{ overflow: "auto", maxHeight: "640px", maxWidth: "1700px" }}>
        <div className="table-wrapperDgReport">
          <Table striped bordered hover  >
            <thead>
              <tr>

                <th >S.No</th>
                <th >Month</th>
                <th >Customer Name</th>
                <th >WEG No.</th>
                <th >Loc. No</th>
                <th >HTSC No. </th>
                <th >Site </th>
                <th >Gen 0</th>
                <th >Gen 1</th>
                <th >Gen 2</th>
                <th >Total Production</th>
                <th >KWH Import EB</th>
                <th >KWH Export EB</th>
                <th >Kvarh Imp EB</th>
                <th >Kvarh exp EB</th>
                <th >Net Exp</th>
                <th >Gen 1 hrs</th>
                <th >Gen 2 hrs</th>
                <th >Gen Hrs</th>
                <th >M/c Fault hrs</th>
                <th >M/c Maintenance hrs</th>
                <th >Grid fault hrs</th>
                <th >Grid down hrs</th>
                <th >Lull hrs</th>
                <th >Machine Availability %</th>
                <th >Grid Availability %</th>
                <th >Kvarh Imp %xp</th>
                <th >Generation @100 % Grid</th>
                <th >Diff. (Panel Vs EB)</th>
                <th >Diff. (Panel Vs EB) %</th>
                <th >Power factor</th>

                <th >Lost KWH - Breakdown (units)  </th>
                <th >Lost KWH - Maintenance (units)  </th>
                <th >Lost KWH - Grid Fault (units)  </th>
                <th >Lost KWH - Grid Down (units) </th>
                <th >Lost KWH - Breakdown (%)</th>
                <th >Lost KWH - Maintenance (%)</th>
                <th >Lost KWH - Grid Fault (%)</th>
                <th >Lost KWH - Grid Down (%)</th>
                <th >Availablity Impact (%)</th>

                <th >Rating</th>
                <th >Kvarh Exp % </th>
                <th >Turbine OK Hrs</th>
                <th >Line hrs</th>
                <th >Line OK hrs</th>
                <th >Line loss (Units)</th>

              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.month}</td>
                  <td>{data.customer_name}</td>
                  <td>{data.wtg_no || 0}</td>
                  <td>{data.loc_no || 0}</td>
                  <td>{data.htsc_no || 0}</td>
                  <td>{data.site_name}</td>
                  <td>{data.gen_zero || 0}</td>
                  <td>{data.gen_one || 0}</td>
                  <td>{data.gen_two || 0}</td>
                  <td>{data.total_production || 0}</td>
                  <td>{data.kwh_imp || 0}</td>
                  <td>{data.kwh_exp || 0}</td>
                  <td>{data.kvarh_imp || 0}</td>
                  <td>{data.kvarh_exp || 0}</td>
                  <td>{data.net_exp || 0}</td>
                  <td>{data.gen_onehrs || 0}</td>
                  <td>{data.gen_twohrs || 0}</td>
                  <td>{data.gen_hourtotal || 0}</td>
                  <td>{data.error_overtotal || "00:00"}</td>
                  <td>{data.maintenance_overtotal || "00:00"}</td>
                  <td>{data.gridfault_overtotal || "00:00"}</td>
                  <td>{data.griddrop_overtotal || "00:00"}</td>
                  <td>{data.lull_hrs || 0}</td>
                  <td>{data.machine_availability || 0}</td>
                  <td>{data.grid_availability || 0}</td>
                  <td>{data.kvarh_imp_per || 0}</td>
                  <td>{data.generation_grid || 0}</td>
                  <td>{data.diff_panel_vs_eb || 0}</td>
                  <td>{data.diff_panel_eb_per || 0}</td>
                  <td>{data.power_factor || 0}</td>
                  <td>{data.breakdown || 0}</td>
                  <td>{data.Maintenance || 0}</td>
                  <td>{data.grid_fault || 0}</td>
                  <td>{data.grid_down || 0}</td>
                  <td>{data.breakdown_per || 0}</td>
                  <td>{data.maintenance_per || 0}</td>
                  <td>{data.grid_fault_per || 0}</td>
                  <td>{data.grid_down_per || 0}</td>
                  <td>{data.availablity_impact || 0}</td>
                  <td>{data.rating || 0}</td>
                  <td>{data.kvarh_exp_per || 0}</td>
                  <td>{data.turbine_ok_hrs || 0}</td>
                  <td>{data.line_hrs || 0}</td>
                  <td>{data.line_ok_hrs || 0}</td>
                  <td>{data.diff_panel_vs_eb || 0}</td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    } else if (formDatarep.report === "yearly") {
      return (
        <div className="table-wrapperDgReport">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Year</th>
                <th>Customer Name</th>
                <th>WEG No.</th>
                <th>Loc. No</th>
                <th>HTSC No.</th>
                <th>Site</th>
                <th>Gen 0</th>
                <th>Gen 1</th>
                <th>Gen 2</th>
                <th>Total Production</th>
                <th>KWH Import EB</th>
                <th>KWH Export EB</th>
                <th>Kvarh Imp EB</th>
                <th>Kvarh exp EB</th>
                <th>Net Exp</th>
                <th>Gen 1 hrs</th>
                <th>Gen 2 hrs</th>
                <th>Gen Hrs</th>
                <th>M/c Fault hrs</th>
                <th>M/c Maintenance hrs</th>
                <th>Grid fault hrs</th>
                <th>Grid down hrs</th>
                <th>Lull hrs</th>
                <th>Machine Availability %</th>
                <th>Grid Availability %</th>
                <th>Kvarh Imp %xp</th>
                <th>Generation @100 % Grid</th>
                <th>Diff. (Panel Vs EB)</th>
                <th>Diff. (Panel Vs EB) %</th>
                <th>Power factor</th>

                <th>Lost KWH - Breakdown (units)</th>
                <th>Lost KWH - Maintenance (units)</th>
                <th>Lost KWH - Grid Fault (units)</th>
                <th>Lost KWH - Grid Down (units)</th>
                <th>Lost KWH - Breakdown (%)</th>
                <th>Lost KWH - Maintenance (%)</th>
                <th>Lost KWH - Grid Fault (%)</th>
                <th>Lost KWH - Grid Down (%)</th>
                <th>Availablity Impact (%)</th>

                <th>Rating</th>
                <th>Kvarh Exp %</th>
                <th>Turbine OK Hrs</th>
                <th>Line hrs</th>
                <th>Line OK hrs</th>
                <th>Line loss (Units)</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.year}</td>
                  <td>{data.customer_name}</td>
                  <td>{data.wtg_no || 0}</td>
                  <td>{data.loc_no || 0}</td>
                  <td>{data.htsc_no || 0}</td>
                  <td>{data.site_name}</td>
                  <td>{data.gen_zero || 0}</td>
                  <td>{data.gen_one || 0}</td>
                  <td>{data.gen_two || 0}</td>
                  <td>{data.total_production || 0}</td>
                  <td>{data.kwh_imp || 0}</td>
                  <td>{data.kwh_exp || 0}</td>
                  <td>{data.kvarh_imp || 0}</td>
                  <td>{data.kvarh_exp || 0}</td>
                  <td>{data.net_exp || 0}</td>
                  <td>{data.gen_onehrs || 0}</td>
                  <td>{data.gen_twohrs || 0}</td>
                  <td>{data.gen_hourtotal || 0}</td>
                  <td>{data.error_overtotal || "00:00"}</td>
                  <td>{data.maintenance_overtotal || "00:00"}</td>
                  <td>{data.gridfault_overtotal || "00:00"}</td>
                  <td>{data.griddrop_overtotal || "00:00"}</td>
                  <td>{data.lull_hrs || 0}</td>
                  <td>{data.machine_availability || 0}</td>
                  <td>{data.grid_availability || 0}</td>
                  <td>{data.kvarh_imp_per || 0}</td>
                  <td>{data.generation_grid || 0}</td>
                  <td>{data.diff_panel_vs_eb || 0}</td>
                  <td>{data.diff_panel_eb_per || 0}</td>
                  <td>{data.power_factor || 0}</td>
                  <td>{data.breakdown || 0}</td>
                  <td>{data.Maintenance || 0}</td>
                  <td>{data.grid_fault || 0}</td>
                  <td>{data.grid_down || 0}</td>
                  <td>{data.breakdown_per || 0}</td>
                  <td>{data.maintenance_per || 0}</td>
                  <td>{data.grid_fault_per || 0}</td>
                  <td>{data.grid_down_per || 0}</td>
                  <td>{data.availablity_impact || 0}</td>
                  <td>{data.rating || 0}</td>
                  <td>{data.kvarh_exp_per || 0}</td>
                  <td>{data.turbine_ok_hrs || 0}</td>
                  <td>{data.line_hrs || 0}</td>
                  <td>{data.line_ok_hrs || 0}</td>
                  <td>{data.diff_panel_vs_eb || 0}</td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div >
      );
    }
    else {
      return null;
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12" md="12" xs="12" className="align-self-center">
            <div className="page-nav py-3">
              <span class="nav-list">DGR REPORT</span>
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <div className="w-100">
              <Calender
                setLabel={(date) => setLabel(date, "from_date")}
                selectedDate={formDatarep.from_date}
                calenderlabel="FromDate"
              />
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <div className="w-100">
              <Calender
                setLabel={(date) => setLabel(date, "to_date")}
                selectedDate={formDatarep.to_date}
                calenderlabel="ToDate"
              />
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <span>
              <label className="mb-2">CustomerName</label>
            </span>
            <div>
              <Select
                isMulti
                options={customerOptions}
                value={customerOptions.filter((option) =>
                  formDatarep.customer_name.includes(option.value)
                )}
                onChange={handleSelectChange}
                isLoading={loading}
                placeholder="Select customers"
              />
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <span>
              <label className="mb-2">Turbine No</label>
            </span>
            <div>
              <Select
                isMulti
                options={filteredTurbineOptions}
                value={filteredTurbineOptions.filter((option) =>
                  formDatarep.wtg_no.includes(option.value)
                )}
                onChange={handleTurbineSelectChange}
                isLoading={loading}
                placeholder="Select turbines"
              />
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <DropDownUI
              optionlist={types}
              placeholder="Report"
              labelname="Report"
              name="report"
              value={formDatarep.report}
              onChange={(updatedFormData) =>
                setFormDatarep({
                  ...formDatarep,
                  report: updatedFormData.report,
                })
              }
            ></DropDownUI>
          </Col>
          <Col lg="9" md="12" xs="12" className="py-3 ">
            <div className="mt-3"></div>
            <div className="d-flex justify-content-end">
              <span className="mx-2">
                <ClickButton
                  label={<>Submit</>}
                  onClick={handleSubmit}
                ></ClickButton>
              </span>
              <span className="mx-2">
                <ClickButton
                  label={<>Download Excel</>}
                  onClick={downloadExcel}
                ></ClickButton>
              </span>
            </div>
          </Col>

          <Col lg="4" md="12" xs="12" className="py-3 "></Col>
          <Col lg="12">
            {loading ? <Spinner animation="border" /> : renderTable()}

            {/* <div style={{ overflow: "auto", maxHeight: "400px", maxWidth: "1400px" }}>
              <Table striped bordered hover  >
                <thead>
                  <tr>

                    <th >S.No</th>
                    <th >Date</th>
                    <th >Customer Name</th>
                    <th >WEG No.</th>
                    <th >Loc. No</th>
                    <th >HTSC No. </th>
                    <th >Site </th>
                    <th >Gen 0</th>
                    <th >Gen 1</th>
                    <th >Gen 2</th>
                    <th >Total Production</th>
                    <th >KWH Import EB</th>
                    <th >KWH Export EB</th>
                    <th >Kvarh Imp EB</th>
                    <th >Kvarh exp EB</th>
                    <th >Net Exp</th>
                    <th >Gen 1 hrs</th>
                    <th >Gen 2 hrs</th>
                    <th >Gen Hrs</th>
                    <th >M/c Fault hrs</th>
                    <th >M/c Maintenance hrs</th>
                    <th >Grid fault hrs</th>
                    <th >Grid down hrs</th>
                    <th >Lull hrs</th>
                    <th >Machine Availability %</th>
                    <th >Grid Availability %</th>
                    <th >Kvarh Imp %xp</th>
                    <th >Generation @100 % Grid</th>
                    <th >Diff. (Panel Vs EB)</th>
                    <th >Diff. (Panel Vs EB) %</th>
                    <th >Power factor</th>
                    <th >Capacity Factor</th>
                    <th >Lost KWH - Breakdown (units)  </th>
                    <th >Lost KWH - Maintenance (units)  </th>
                    <th >Lost KWH - Grid Fault (units)  </th>
                    <th >Lost KWH - Grid Down (units) </th>
                    <th >Lost KWH - Breakdown (%)</th>
                    <th >Lost KWH - Maintenance (%)</th>
                    <th >Lost KWH - Grid Fault (%)</th>
                    <th >Lost KWH - Grid Down (%)</th>
                    <th >Availablity Impact (%)</th>
                    <th >Capacity Factor @100 % Grid</th>
                    <th >Rating</th>
                    <th >Kvarh Exp % </th>
                    <th >Turbine OK Hrs</th>
                    <th >Line hrs</th>
                    <th >Line OK hrs</th>
                    <th >Line loss (Units)</th>

                  </tr>
                </thead>
                <tbody>
                  {userData.map((data, index) => (
                    <tr key={index}>

                      <td>{index + 1}</td>
                      <td>{data.dg_date}</td>
                      <td>{data.customer_name}</td>
                      <td>{data.wtg_no}</td>
                      <td>{data.location_no}</td>
                      <td></td>
                      <td></td>
                      <td>{data.gen_zero}</td>
                      <td>{data.gen_one}</td>
                      <td>{data.gen_two}</td>
                      <td>{data.total_production}</td>
                      <td>{data.kwh_imp}</td>
                      <td>{data.kwh_exp}</td>
                      <td>{data.kvarh_imp}</td>
                      <td>{data.kvarh_exp}</td>
                      <td>{data.net_exp}</td>
                      <td>{data.gen_onehrs}</td>
                      <td>{data.gen_twohrs}</td>
                      <td>{data.gen_hourtotal}</td>
                      <td>{data.error_overtotal}</td>
                      <td>{data.maintenance_overtotal}</td>
                      <td>{data.gridfault_overtotal}</td>
                      <td>{data.griddrop_overtotal}</td>
                      <td>{data.lull_hrs}</td>
                      <td>{data.machine_availability}</td>
                      <td>{data.grid_availability}</td>
                      <td>{data.kvarh_imp_per}</td>
                      <td>{data.generation_grid}</td>
                      <td>{data.diff_panel_vs_eb}</td>
                      <td>{data.diff_panel_eb_per}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{data.Maintenance}</td>
                      <td>{data.grid_fault}</td>
                      <td>{data.grid_down}</td>
                      <td>{data.breakdown_per}</td>
                      <td>{data.maintenance_per}</td>
                      <td>{data.grid_fault_per}</td>
                      <td>{data.grid_down_per}</td>
                      <td>{data.availablity_impact}</td>
                      <td></td>
                      <td></td>
                      <td>{data.kvarh_exp_per}</td>
                      <td>{data.turbine_ok_hrs}</td>
                      <td>{data.line_hrs}</td>
                      <td>{data.line_ok_hrs}</td>
                      <td>{data.diff_panel_vs_eb}</td>

                    </tr>
                  ))}
                </tbody>
              </Table>
            </div> */}


          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DgReport;
