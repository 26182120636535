import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button, Modal } from 'react-bootstrap'
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from '../Compnents/Table';
import Pagnation from '../Compnents/Pagnation';
import { Buttons, ClickButton } from '../Compnents/ClickButton';
import { TextInputForm } from '../Compnents/Forms';
import MobileView from '../Compnents/MobileView';
import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoMdCloseCircle } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md"
import { PageTitle } from '../Compnents/PageTitle';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UserTablehead = ["No", "Contract Type", "Contract Code", "Action",]
// const UserData = [
//   { values: ["1", " COAMC", "Comprehensive Operational Annual Maintenance Contract",] },
//   { values: ["2", "AOMC", "Annual Operational Maintenance Contract",] },
//   { values: ["3", "AMC", "Annual Maintenance Contract",] },]

const UserData = [
  {
    "id": "1",
    "Contract_Type": "COAMC",
    "Description": "Comprehensive Operational Annual Maintenance Contract"
  },
  {
    "id": "1",
    "Contract_Type": "AOMC",
    "Description": "Annual Operational Maintenance Contract"
  }
]
const ContractType = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modal, setModal] = useState(false);

  const showModal = () => setModal(true);
  const handleLoad = () => {
    window.location.reload();
  }
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [crtContract, setCrtContract] = useState(null);
  const handleEditClick = (rowdata) => {
    setCrtContract(rowdata)
    showModal(true)

  };
  const closeModal = () => {

    setModal(false);
    setCrtContract(null);
  };
  const initialState = type === 'edit' ? { ...rowData } : {
    contract_name: '',
    contract_code: ''
  };
  const [formData, setFormData] = useState(initialState);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtContract != null) {
      setCrtContract({
        ...crtContract,
        [fieldName]: value
      })
    }
    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  const [userData, setUserData] = useState([])
  console.log('userData', userData);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const fetchData = async () => {
    try {
      const response = await fetch('https://api.srivarugreenenergy.com/contract_type/list.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: searchText
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data.contract_type);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect

  }, [searchText]);
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }
  const handleSubmit = async () => {
    try {
      if (formData.contract_name === '' || formData.contract_code === '') {
        if (formData.contract_name === '') {
          errorAlert("Name is Must");
        } else if (formData.contract_code === '') {
          errorAlert("Mobile Number is Must");
        }

      } else {
        const response = await fetch('https://api.srivarugreenenergy.com/contract_type/create.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        console.log(formData);
        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          setFormData({
            contract_name: '',
            contract_code: ''
          })
          fetchData();
          successAlert(responseData.msg)
          setTimeout(() => {
            closeModal();
          }, 2000);
        }
        else if (responseData.status === 400) {
          errorAlert(responseData.msg)
        }
        else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const response = await fetch('https://api.srivarugreenenergy.com/contract_type/update.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          contract_id: crtContract.contract_id,
          contract_name: crtContract.contract_name,
          contract_code: crtContract.contract_code

        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {

        toast.success('Contract updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        fetchData();
        setTimeout(() => {
          closeModal();
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg='7' md='4' xs='6'>
            <div className='page-nav py-3'>
              <span class="nav-list"> ContractType</span>
            </div>
          </Col>
          <Col lg='5' md='3' xs='6' className='align-self-center text-end'>
            <ClickButton label={<>Add New</>} onClick={showModal}></ClickButton>
          </Col>
          <Col lg='3' md='5' xs='12' className='py-1'>
            <TextInputForm placeholder={"ContractType"}  onChange={(e) => handleSearch(e.target.value)} prefix_icon={<FaMagnifyingGlass />} labelname={" Search Type"}> </TextInputForm>
          </Col>
          <Col lg={6} md={12} xs={12} className='py-2 text-end'>
            {/* <Button onClick={handleShow} className='filter' >
              <span className='me-2'><IoFilter /></span>Filter
            </Button> */}
            <Col lg='12' md='12' xs='6' className='py-1'>
              {/* <Button onClick={handleLoad} className='filter' >
                <span className='me-2'><IoFilter /></span>Undo Filter
              </Button> */}
            </Col>
            <Offcanvas show={show} onHide={handleClose} placement='end' backdrop={true}>
              <Offcanvas.Body>
                <Row>
                  <Col lg="6">
                    <PageTitle PageTitle={<>Contract Type</>} />
                  </Col>
                  <Col lg="6" className='align-self-center'>
                    <div className='text-end'>
                      <Buttons onClick={handleClose} lable={<><MdOutlineClose /></>}></Buttons>
                    </div>
                  </Col>
                </Row>
                <div className='mt-3'>
                  <Row>
                    <Col lg='12' md='12' xs='12' className='py-3'>
                      <TextInputForm
                        placeholder={"Contract Code"}
                        labelname={" Contract Code"}
                        name='contract_code'
                        value={formData.contract_code}
                        onChange={(e) => handleChange(e, 'contract_code')}
                      ></TextInputForm>
                    </Col>

                  </Row>
                </div>
                <Col lg="12" className='align-self-center'>
                  <div className='my-5 text-center'>
                    <ClickButton label={<>Clear</>} ></ClickButton>
                  </div>
                </Col>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          {/* <Col lg={3} md={12} xs={12} className='py-2'>
            <Pagnation></Pagnation>
          </Col> */}
          <Col lg='12' md='12' xs='12' className='px-0'>
            <div className='py-1'>
              <TableUI headers={UserTablehead} body={userData} type="contracttype" pageview={"yes"} handleEditClick={handleEditClick} onDelete={fetchData} style={{ 'borderRadius': '5px' }} />
              <MobileView sno={"01"} FactoryName={"Anand Fireworks"} Place={"Vettrilaiyurani"}></MobileView>
            </div>
          </Col>
          {/* <Col lg={12} md={12} xs={12}>
            <Pagnation></Pagnation>
          </Col> */}
        </Row>
      </Container>

      <>
        <Modal show={modal} onHide={closeModal} size='md' centered>
          <Modal.Header closeButton>
            <Modal.Title>Contract Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Container>
              <Row>
                <Col lg='12' md='12' xs='12' className='py-3'>
                  <TextInputForm placeholder={"Contract Name"} labelname={" Contract Name"}
                    name='contract_name'
                    value={crtContract != null ? crtContract.contract_name : formData.contract_name}
                    onChange={(e) => handleChange(e, 'contract_name')}
                  ></TextInputForm>
                </Col>
                <Col lg='12' md='12' xs='12' className='py-3'>
                  <TextInputForm placeholder={"Contract Code"} labelname={" Contract Code"}
                    name='contract_code'
                    value={crtContract != null ? crtContract.contract_code : formData.contract_code}
                    onChange={(e) => handleChange(e, 'contract_code')}
                  ></TextInputForm>
                </Col>
                {/* <Col lg='12' md='12' xs='12' className='py-3'>
                  <TextInputForm labelname={"Description"} />
                </Col> */}
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Col lg='12' className='py-5 align-self-center'>
              <div className='text-center'>
                {crtContract != null ? (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <div className='d-flex justify-content-center'>
                      <span className='mx-2'>
                        <ClickButton label={<>Update</>} onClick={handleUpdateSubmit} ></ClickButton>
                      </span>
                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={closeModal}></ClickButton>
                      </span>
                    </div>

                  </>
                ) : (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <div className='d-flex justify-content-center'>
                      <span className='mx-2'>
                        <ClickButton label={<> Submit</>} onClick={handleSubmit}></ClickButton>
                      </span>
                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={closeModal}></ClickButton>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    </>

  )
}

export default ContractType