import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "./Compnents/sidebar/SideBar";
import "./Compnents/sidebar/sidebar.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import DashBoard from "./pages/DashBoard";
import Company from "./pages/Company";
import User from "./pages/User";
import UserCreation from "./pages/UserCreation";
import ContractType from "./pages/ContractType";
import Site from "./pages/Site";
import Location from "./pages/Location";
import Model from "./pages/Model";
import Customer from "./pages/Customer";
import Turbine from "./pages/Turbine";
import Error from "./pages/Error";
import DailyGeneration from "./pages/DailyGeneration";
import DgCreation from "./pages/DgCreation";
import ContractTypeCreation from "./pages/ContractTypeCreation";
import SiteCreation from "./pages/SiteCreation";
import LocationCreation from "./pages/LocationCreation";
import CustomerCreation from "./pages/CustomerCreation";
import TurbineCreation from "./pages/TurbineCreation";
import GridFault from "./pages/GridFault";
import Maintenance from "./pages/Maintenance";
import GridDrop from "./pages/GridDrop";
import DgReport from "./pages/DgReport";
import Role from "./pages/Role";
import CustomerGroup from "./pages/CustomerGroup";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (session) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem("session", "true");
    setLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("session");
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={loggedIn ? (<Navigate to="/dashboard" replace />) : (<Navigate to="/login" replace />)}></Route>
          <Route path="/login" element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path="/dashboard" element={<DashBoard />}></Route>
            <Route path="/console/user" element={<User />}></Route>
            <Route
              path="/console/user/create"
              element={<UserCreation />}
            ></Route>
            <Route path="/console/company" element={<Company />}></Route>
            <Route path="/console/master/role" element={<Role />}></Route>
            <Route
              path="/console/master/contracttype"
              element={<ContractType />}
            ></Route>
            <Route
              path="/console/master/contracttype/create"
              element={<ContractTypeCreation />}
            ></Route>
            <Route path="/console/master/site" element={<Site />}></Route>
            <Route
              path="/console/master/site/create"
              element={<SiteCreation />}
            ></Route>
            <Route
              path="/console/master/location"
              element={<Location />}
            ></Route>
            <Route
              path="/console/master/location/create"
              element={<LocationCreation />}
            ></Route>
            <Route path="/console/master/model" element={<Model />}></Route>
            <Route
              path="/console/master/customergroup"
              element={<CustomerGroup />}
            ></Route>
            <Route
              path="/console/master/customer"
              element={<Customer />}
            ></Route>
            <Route
              path="/console/master/customer/create"
              element={<CustomerCreation />}
            ></Route>
            <Route path="/console/master/turbine" element={<Turbine />}></Route>
            <Route
              path="/console/master/turbine/create"
              element={<TurbineCreation />}
            ></Route>
            <Route path="/console/master/error" element={<Error />}></Route>
            <Route
              path="/console/master/gridfault"
              element={<GridFault />}
            ></Route>
            <Route
              path="/console/master/maintenance"
              element={<Maintenance />}
            ></Route>
            <Route
              path="/console/master/griddrop"
              element={<GridDrop />}
            ></Route>
            <Route
              path="/console/dailygeneration"
              element={<DailyGeneration />}
            ></Route>
            <Route
              path="/console/dailygeneration/create"
              element={<DgCreation />}
            ></Route>
            <Route path="/console/report/dgr" element={<DgReport />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default App;
