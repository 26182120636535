import React from 'react'
import { Button } from 'react-bootstrap'
import './Button.css'
const ClickButton = ({ label, onClick }) => {
  return (
    <div>
      <Button className='create-btn' onClick={onClick}>{label} </Button>
    </div>
  )
}
const InstantCreate = ({ label, onClick, modeltitle, placeholder, labelname }) => {

  return (
    <div>
      <Button className='instant-add' onClick={onClick}>{label} </Button>
    </div>
  )
}
const Buttons = ({ lable, onClick }) => {
  return (
    <div className='add-new'><Button onClick={onClick}>{lable}</Button></div>
  )
}
const Delete = ({ label, onClick }) => {
  return (
    <>
      <Button className='delete' onClick={onClick}>{label} </Button>
    </>
  )
}

export { ClickButton, InstantCreate, Buttons, Delete } 