import React, { useState, useEffect } from "react";
import { Container, Col, Row, Modal } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../Compnents/Table";
import Pagnation from "../Compnents/Pagnation";
import { ClickButton } from "../Compnents/ClickButton";
import { TextInputForm } from "../Compnents/Forms";
import ModelModal from "../Compnents/Modal";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const UserTablehead = ["No", "Model", "Action"];
// const UserData = [
//   { values: ["1", "TNW 890",] },
//   { values: ["2", "YUMKL",] },
//   { values: ["3", "yu90jjd 7880",] },]

const UserData = [
  {
    id: "1",
    Model: "TNW 890",
  },
];
const ContractType = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setCrtModal(null);
  };
  const handleShowModal = () => setShowModal(true);

  const location = useLocation();
  const { type, rowData } = location.state || {};
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          model_type: "",
        };
  const [formData, setFormData] = useState(initialState);
  const [crtModal, setCrtModal] = useState(null);
  const handleEditClick = (rowdata) => {
    setCrtModal(rowdata);
    setShowModal(true);
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtModal != null) {
      setCrtModal({
        ...crtModal,
        [fieldName]: value,
      });
    }
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/model/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: searchText,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data.model);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [searchText]);
  const [showAlert, setShowAlert] = useState(false);

  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async () => {
    try {
      if (formData.model_type === "") {
        if (formData.model_type === "") {
          errorAlert("Name is Must");
        }
      } else {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/model/create.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        console.log(formData);
        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          setFormData({
            model_type: "",
          });
          fetchData();
          successAlert(responseData.msg);
          setTimeout(() => {
            handleCloseModal();
          }, 2000);
        } else if (responseData.status === 400) {
          errorAlert(responseData.msg);
        } else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/model/update.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Include the company ID in the request
            model_id: crtModal.model_id,
            model_type: crtModal.model_type,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        successAlert(responseData.msg);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);

        // Navigate to the user list page after a delay
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.msg);
    }

    setLoading(false);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list"> Model</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              className="create-btn"
              onClick={handleShowModal}
            ></ClickButton>
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Model"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search Model"}
            >
              {" "}
            </TextInputForm>
          </Col>
          {/* <Col lg={9} md={12} xs={12} className='py-2'>
            <Pagnation></Pagnation>
          </Col> */}
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              <div className="bg-light">
                <div className="user-data">
                  <div className="sno">01</div>
                  <div className="user-name">
                    <div>Boopathi</div>
                    <span className="role"> Super Admin</span>
                  </div>
                  <div className="action-button">
                    <FaAngleRight />
                  </div>
                </div>
              </div>
              <div className="user-data">
                <div className="sno">02</div>
                <div className="user-name">
                  <div>Selvaraj</div>
                  <span className="role"> Super Admin</span>
                </div>
                <div className="action-button">
                  <FaAngleRight />
                </div>
              </div>
              <div className="user-data">
                <div className="sno">03</div>
                <div className="user-name">
                  <div>Boopathi</div>
                  <span className="role"> Super Admin</span>
                </div>
                <div className="action-button">
                  <FaAngleRight />
                </div>
              </div>
              <TableUI
                headers={UserTablehead}
                body={userData}
                type="model"
                pageview={"yes"}
                handleEditClick={handleEditClick}
                onDelete={fetchData}
                style={{ borderRadius: "5px" }}
              />
            </div>
          </Col>
          {/* <Col lg={12} md={12} xs={12}>
            <Pagnation></Pagnation>
          </Col> */}
        </Row>
      </Container>
      <>
        <Modal show={showModal} onHide={handleCloseModal} size="md" centered>
          <Modal.Header>
            <Modal.Title> Model Creation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputForm
              placeholder={"Model Type"}
              name="model_type"
              value={
                crtModal != null ? crtModal.model_type : formData.model_type
              }
              onChange={(e) => handleChange(e, "model_type")}
            ></TextInputForm>
          </Modal.Body>
          <Modal.Footer>
            <Col lg="12" className="py-5 align-self-center">
              <div className="text-center">
                {crtModal != null ? (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <div className="d-flex justify-content-center">
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={handleCloseModal}
                        ></ClickButton>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <div className="d-flex justify-content-center">
                      <span className="mx-2">
                        <ClickButton
                          label={<> Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={handleCloseModal}
                        ></ClickButton>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default ContractType;
