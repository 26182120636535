import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Modal, Table } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../Compnents/Table";
import Pagnation from "../Compnents/Pagnation";
import { ClickButton, Delete, Buttons } from "../Compnents/ClickButton";
import { Calender, TextInputForm } from "../Compnents/Forms";
import { useNavigate } from "react-router-dom";
import { MdDriveFolderUpload } from "react-icons/md";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { MdKeyboardArrowRight } from "react-icons/md";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineClose } from "react-icons/md";
import { PageTitle } from "../Compnents/PageTitle";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
// const UserTablehead = [
//   "No",
//   <span onClick={sortTurbine}>
//     Turbine No <FaAngleRight />
//   </span>,
//   <span onClick={sortDate}>
//     Date <FaAngleRight />
//   </span>,
//   "OverTotalHours",
//   "Action",
// ];

const DailyGeneration = ({ onFileChange }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [viewDgr, setviewDgr] = useState(false);
  const closeModal = () => setviewDgr(false);
  const showModal = () => {
    setviewDgr(true);
    setShow(false);
  };

  const [viewDgrUn, setviewDgrUn] = useState(false);
  const closeModalUn = () => setviewDgrUn(false);
  const showModalun = () => {
    setviewDgrUn(true);
    setShow(false);
  };
  const [sortOrderTurbine, setSortOrderTurbine] = useState("asc");
  const [sortOrderDate, setSortOrderDate] = useState("asc");
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      // Call the callback function passed as a prop
      onFileChange(file);
    }
  };
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/daily_generation/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: searchText,
          }),
        }
      );
      console.log(
        "json",
        JSON.stringify({
          search_text: "",
        })
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        console.log("test");
        setUserData(responseData.data.daily_generation);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText]);

  const [formData, setFormData] = useState({
    date: "",
  });
  const [verifiedData, setVerifiedData] = useState({
    verifiedCount: 0,
    updateCount: 0,
    balance: 0,
    customerdetails: [
      {
        wtg_no: "",
        customer_name: "",
      },
    ],
  });
  console.log("verifiedData", verifiedData);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const fetchDatas = async () => {
    try {
      const response = await fetch(
        "https://api.srivarugreenenergy.com/daily_generation/dgrverified.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: formData.date,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.status === 200) {
        setVerifiedData({
          verifiedCount: responseData.data.verified.verifiedcount || 0,
          updateCount: responseData.data.verified.updatecount || 0,
          balance: responseData.data.verified.balance || 0,
          customerdetails: responseData.data.verified.customer_details || 0,
        });
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error("Failed to fetch data. Please try again later.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchDatas();
  };
  const sortTurbine = () => {
    const sortedData = [...userData].sort((a, b) => {
      const wtgNoA = String(a.wtg_no);
      const wtgNoB = String(b.wtg_no);
      if (sortOrderTurbine === "asc") {
        return wtgNoA.localeCompare(wtgNoB);
      } else {
        return wtgNoB.localeCompare(wtgNoA);
      }
    });
    setUserData(sortedData);
    setSortOrderTurbine(sortOrderTurbine === "asc" ? "desc" : "asc");
  };
  const sortDate = () => {
    const sortedData = [...userData].sort((a, b) => {
      const dateA = new Date(a.dg_date).getTime();
      const dateB = new Date(b.dg_date).getTime();
      return sortOrderDate === "asc" ? dateA - dateB : dateB - dateA;
    });
    setUserData(sortedData);
    setSortOrderDate(sortOrderDate === "asc" ? "desc" : "asc");
  };

  const UserTablehead = [
    "No",
    <span onClick={sortTurbine} style={{ cursor: "pointer" }}>
      Turbine No <FaSortAlphaDown />
    </span>,
    <span onClick={sortDate} style={{ cursor: "pointer" }}>
      Date <FaSortAlphaDown />
    </span>,
    "OverTotalHours",
    "Action",
  ];
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="3" className="align-self-center">
            <div className="page-nav py-3">
              <span class="nav-list">Daily Generation</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="9" className="align-self-center py-3">
            <div className="d-flex justify-content-end">
              <div className="px-2">
                <ClickButton
                  label={<>Verify DGR</>}
                  className="create-btn "
                  onClick={handleShow}
                ></ClickButton>
              </div>
              <div className="px-2">
                <ClickButton
                  label={<>Add New</>}
                  className="create-btn "
                  onClick={() => navigate("/console/dailygeneration/create")}
                ></ClickButton>
              </div>
            </div>
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <Form className="form page-nav">
              <TextInputForm
                placeholder={"Turbine No"}
                onChange={(e) => handleSearch(e.target.value)}
                prefix_icon={<FaMagnifyingGlass />}
              >
                {" "}
              </TextInputForm>
            </Form>
          </Col>

          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-2 c-table table-scroll">
              <TableUI
                headers={UserTablehead}
                body={userData}
                onDelete={fetchData}
                pageview={"yes"}
                style={{ borderRadius: "5px" }}
                type="dailygen"
              />
            </div>
          </Col>
          <>
            <Modal show={show} centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>DGR Verification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Tabs
                    defaultActiveKey="verified"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                  >
                    <Tab eventKey="verified" title="Verified">
                      <div className="d-flex">
                        <span className="mx-2">
                          <div className="w-70">
                            <Calender
                              setLabel={(date) => setLabel(date, "date")}
                              selectedDate={formData.date}
                              calenderlabel="Date"
                            />
                          </div>
                        </span>
                        <span className="mx-2 mt-3">
                          <ClickButton
                            label={<>Submit</>}
                            onClick={handleSubmit}
                          ></ClickButton>
                        </span>
                      </div>
                      <Table>
                        <tr>
                          <td>Verified Count</td>
                          <td>Update DGR Count</td>
                          <td>Balance </td>
                          <td className="text-center"># </td>
                        </tr>
                        <tbody>
                          <tr>
                            <td>{verifiedData.verifiedCount}</td>
                            <td>{verifiedData.updateCount}</td>
                            <td>{verifiedData.balance}</td>
                            <td className="text-center">
                              <Delete
                                label={
                                  <>
                                    View
                                    <span>
                                      <MdKeyboardArrowRight />
                                    </span>
                                  </>
                                }
                                onClick={showModal}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                    {/* <Tab eventKey="unverified" title="Un Verified">
                                            <Table>
                                                <tr>
                                                    <td>Un Verified DGR </td>
                                                    <td>60</td>
                                                    <td>
                                                        <div className='text-end'>
                                                            <Delete label={<>View<span><MdKeyboardArrowRight /></span></>} onClick={showModalun} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Table>
                                        </Tab> */}
                  </Tabs>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <ClickButton
                  label={<>Cancel</>}
                  onClick={handleClose}
                ></ClickButton>
                <ClickButton label={<>Submit</>}></ClickButton>
              </Modal.Footer>
            </Modal>
          </>
          <>
            <Modal show={viewDgr} centered size="xs" onHide={closeModal}>
              {/* <Modal.Header closeButton>
                                <Modal.Title> Verified DGR list</Modal.Title>
                            </Modal.Header> */}
              <Modal.Body>
                <Row>
                  <Col lg="6">
                    <PageTitle PageTitle={<>Verified DGR list</>} />
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div className="text-end">
                      <Buttons
                        onClick={closeModal}
                        lable={
                          <>
                            <MdOutlineClose className="icon-style" />
                          </>
                        }
                      ></Buttons>
                    </div>
                  </Col>
                </Row>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <div className="table-wrapper">
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr>
                              <th style={{ border: '1px solid black', padding: '8px' }}>S.No</th>
                              <th style={{ border: '1px solid black', padding: '8px' }}>Turbine No</th>
                              <th style={{ border: '1px solid black', padding: '8px' }}>Customer Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {verifiedData.customerdetails.map((verifi, verifie) => (
                              <tr key={verifie}>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{verifie + 1}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{verifi.wtg_no}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{verifi.customer_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
          </>
          <>
            <Modal show={viewDgrUn} centered size="xs" onHide={closeModalUn}>
              <Modal.Header closeButton>
                <Modal.Title>UN Verified DGR list</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <table>
                        <tr>
                          <td>Turbine No</td>
                          <td>Customer Name</td>
                          <td className="text-center">#</td>
                        </tr>
                        <tbody>
                          <tr>
                            <td>142</td>
                            <td>TR Marketing</td>
                            <td className="text-end">
                              <div>
                                <Delete
                                  label={<> View</>}
                                  onClick={() =>
                                    navigate("/console/dailygeneration/create")
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
          </>
        </Row>
      </Container>
    </>
  );
};

export default DailyGeneration;
